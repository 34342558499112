import { AdvancedSearchQueryBuilder } from './advanced-search.model';

export function advancedSearchQueryBuilder(
    partial: Partial<AdvancedSearchQueryBuilder>,
): AdvancedSearchQueryBuilder {
    return {
        some: null,
        every: null,
        exactly: null,
        none: null,
        ...partial,
    };
}

export const specialOperatorExp = /[+-]/;
export const wrappedTokenExp = /"([^"]*)"/;
export const allwrappedTokensExp = /"([^"]*)"/g;
export const allNoOperatorTokensExp = /"([^"]*)"|\S+/g;
export const allTokensExp = /([+-]?")([^"]*)"|\S+/g;
